<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <!-- <brands
        :brands="brands"
        @selectedBrand="brandSelectHandler"
        :selectedBrand="selectedBrand"
      /> -->
      <v-container
        class="pa-4"
        style="border:1px solid #00B284; background-color:white"
      >
        <v-row class="mt-4 px-12">
          <v-text-field
            outlined
            rounded
            placeholder="search..."
            @keyup="searchHandler()"
            v-model="searchKeyWord"
          ></v-text-field>
        </v-row>
        <v-row
          v-for="(car, index) in filteredCars"
          :key="index"
          class="car_container"
        >
          <v-col cols="12" xl="4" lg="4" md="4" sm="4">
            <div class="h-100 justify-content-center align-items-center d-flex">
              <v-img
                :src="car.bannerImage"
                width="100%"
                class="card"
                contain
                max-width="300px"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="12" xl="8" lg="8" md="8" sm="8">
            <!-- <div class=""> -->
            <div class="car_name ">{{ car.name }}</div>
            <div class="car_info_container ">
              <div
                class="car_info"
                v-for="(detail, index) in details"
                :key="index"
              >
                <div class="car_info_cell ">
                  <div class="car_info_cell_name">{{ detail.name }}</div>
                  <div class="car_info_cell_details">
                    {{ car[detail.key] }}
                  </div>
                </div>
                <span
                  v-if="index != details.length - 1"
                  class="borderLine"
                ></span>
              </div>
            </div>
            <!-- Buttons -->
            <div class="car_info_btn_container">
              <div class="car_btn" @click="carDetailsHandler(car.id)">
                <i class="fas fa-search-plus car_btn_icon"></i> Details
                Information
              </div>
              <div class="car_btn" @click="copyLink(car.id, index)">
                <i class="fas fa-share-alt car_btn_icon"></i
                >{{ carLinkIndex == index ? "Link Copied" : "Share This" }}
              </div>
              <div
                v-if="!car.activeStatus"
                class="car_btn"
                style="background-color:red; border:none; opacity:.8"
              >
                sold
              </div>
            </div>
            <!-- </div> -->
          </v-col>

          <v-col cols="12">
            <div class="under_line"></div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import * as slug from "../config/slug.js";
import Brands from "../components/showcase/brands.vue";
export default {
  beforeMount() {
    this.setStatus();
    this.getAllBrands();
    // this.getAllCars();
  },
  components: {
    Brands,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      brands: [],
      cars: [],
      filteredCars: [],
      searchKeyWord: "",
      details: [
        {
          name: "Mileage",
          key: "mileage",
        },
        {
          name: "Fuel Type",
          key: "fuelType",
        },
        {
          name: "Engine",
          key: "engine",
        },
        {
          name: "Interior",
          key: "interiorColor",
        },
        {
          name: "Exterior",
          key: "exteriorColor",
        },
      ],
      selectedBrand: this.$route.query.brand ? this.$route.query.brand : null,
      activeStatus: true,
      carLinkIndex: null,
    };
  },
  methods: {
    setStatus() {
      let status = this.$route.params.status;
      if (status === "available") {
        this.activeStatus = 1;
      }
      if (status === "sold") {
        this.activeStatus = 0;
      }
      this.getAllCars();
    },
    getAllBrands() {
      this.$http
        .get(api.BRAND)
        .then((res) => {
          this.brands = res.data;
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {});
    },
    getAllCars() {
      // console.log(api.CAR + "/" + this.activeStatus);
      this.loadingState = true;
      this.$http
        .get(api.ALL_CAR + "/" + this.activeStatus)
        .then((res) => {
          this.cars = res.data;
          // console.log("Res==>", res.data);
          this.filteredCars = [...this.cars];
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    carDetailsHandler(carId) {
      this.$router.push({ path: slug.CAR_DETAILS, query: { id: carId } });
    },
    copyLink(carId, carLinkIndex) {
      console.log("navigator===>", window.isSecureContext);
      navigator.clipboard.writeText(
        `${window.location.host}${slug.CAR_DETAILS}?id=${carId}`
      );
      // alert("Link Copied");
      this.carLinkIndex = carLinkIndex
    },
    brandSelectHandler(brand) {
      this.selectedBrand = this.selectedBrand == brand ? null : brand;
    },
    searchHandler() {
      let keyword = this.searchKeyWord.trim();
      if (keyword.length > 0) {
        this.filteredCars = this.cars.filter((e) => {
          return e.name.toLowerCase().includes(keyword.toLowerCase());
        });
      } else {
        this.filteredCars = [...this.cars];
      }

      console.log("filterd====>", this.filteredCars, keyword);
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    "$route.params"() {
      this.setStatus();
    },
  },
};
</script>

<style scoped>
.car_container {
  /* border-radius: 5px; */
  padding: 5px;
  /* margin-bottom: 15px; */
}
.under_line {
  border: 3px solid #392bff77;
  height: 1px;
  border-radius: 3px;
}
.car_name {
  font-size: 30px;
  font-weight: 500;
  color: rgb(38, 36, 41);
}
.car_info_container {
  display: flex;
}
.car_info {
  display: flex;
  align-items: center;
}
.car_info_cell {
  text-align: left;
  padding: 3px 0;
  font-size: 18px;
  font-weight: 500;
  padding-right: 15px;
}
.car_info_cell:first-child {
  padding-left: 0;
}

.car_info_cell_name {
  color: rgb(0, 27, 77);
  font-weight: 600;
}
.car_info_btn_container {
  /* justify-content: center; */
  display: flex;
}
.car_btn {
  font-size: 18px;
  font-weight: 500;
  padding: 0px 15px;
  border: 1px solid grey;
  border-radius: 15px;
  background-color: rgb(118, 115, 131);
  color: rgb(255, 255, 255);
  margin-right: 10px;
  text-align: center;
}
.car_btn:hover {
  cursor: pointer;
}
.car_btn_icon {
  color: white;
  padding-right: 5px;
  font-size: 16px;
}

.borderLine {
  width: 2px;
  height: 55%;
  border-left: 2px solid black;
  padding-right: 15px;
}

.search_text {
  background-color: black;
  color: white;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  padding: 15px 5px;
}
.filter_container {
  padding: 50px 30px 30px 30px;
  border: 1px solid black;
}
.filter_field {
  margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
  .car_name {
    font-size: 20px;
    border-bottom: 3px solid rgb(131, 131, 131);
    margin-bottom: 10px;
    text-align: center;
  }
  .car_info_container {
    /* flex-wrap: wrap;
    justify-content: center; */
    display: block;
  }
  .car_info {
    display: block;
  }
  .car_info_cell {
    display: block;
    /* border: 1px solid red; */
  }
  .car_info_cell_name,
  .car_info_cell_details {
    display: inline-block;
    /* border: 1px solid red; */
    width: 50%;
  }
  .car_info_cell_details {
    text-align: right;
  }
  .borderLine {
    border: none;
    display: block;
    width: 100%;
    border-bottom: 2px solid rgb(134, 134, 134);
  }

  .car_btn {
    font-size: 16px;
    flex-basis: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

@media only screen and (max-width: 500px) {
  .car_info_btn_container {
    display: block;
  }
  .car_btn {
    display: block;
    margin-top: 15px;
    padding: 10px 15px;
    background-color: rgba(0, 0, 0, 0.2);

    color: black;
    font-weight: 600;
    font-size: 20px;
  }
}
</style>
