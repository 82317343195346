<template>
  <v-col
    cols="12"
    xl="3"
    lg="3"
    md="4"
    sm="6"
    class="pa-6 car_container"
    style="overflow:hidden;"
  >
    <!-- Car Image -->
    <v-row>
      <v-img
        style=" position:relative"
        width="100%"
        aspect-ratio="1.33"
        :src="car.bannerImage"
        lazy-src="https://picsum.photos/id/11/100/60"
      >
        <div class="car_details_backdrop" @click="carClickHandler(car.id)">
          Details
        </div>
      </v-img>
    </v-row>

    <!-- Car Info -->
    <v-row class="car_info_container">
      <v-col cols="12" class="car_name">
        <div>{{ car.name }}</div>
      </v-col>
      <v-col cols="4" class="car_info " style="position:relative">
        <div class="text-center">
          <v-img
            src="../../assets/engineIcon.png"
            width="25px"
            aspect-ratio="1"
            class="mx-auto"
          ></v-img>
          <div>{{ car.engine }}</div>
        </div>
        <span class="border_right"></span>
      </v-col>
      <v-col cols="4" class="car_info " style="position:relative">
        <div>
          <v-icon color="black" size="20">fa-gas-pump</v-icon>
          <div>{{ car.fuelType }}</div>
        </div>
        <span class="border_right"></span>
      </v-col>
      <v-col cols="4" class="car_info">
        <div>
          <v-icon color="black" size="20">fa-calendar-alt</v-icon>
          <div>{{ car.model }}</div>
        </div>
      </v-col>
      <v-col cols="12" class="pa-2">
        <div class="car_info_btn_container">
          <div class="car_btn" @click="carClickHandler(car.id)">
            <i class="fas fa-search-plus car_btn_icon"></i> Details
          </div>
          <div class="car_btn" @click="copyLink(car.id)">
            <i class="fas fa-share-alt car_btn_icon"></i
            >{{ linkCopied ? "Link Copied" : "Share This" }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import * as slug from "../../config/slug.js";

export default {
  beforeMount() {},
  beforeDestroy() {},
  components: {},
  computed: {},
  data() {
    return {
      linkCopied: false,
    };
  },
  methods: {
    carClickHandler(id) {
      // console.log('id- ', id)
      this.$router.push({ path: slug.CAR_DETAILS, query: { id } });
    },
    copyLink(carId) {
      // console.log("navigator===>", window.isSecureContext);
      navigator.clipboard.writeText(
        `${window.location.host}${slug.CAR_DETAILS}?id=${carId}`
      );
      // alert("Link Copied");
      this.linkCopied = true;
    },
  },
  mounted() {},
  props: {
    car: {
      type: Object,
      default: () => {},
    },
  },
  updated() {},
  watch: {
    car() {
      console.log("car");
      this.linkCopied = false;
    },
  },
};
</script>

<style scoped>
.car_info_container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);
  /* border-bottom-left-radius: 10px; */
  /* border-bottom-right-radius: 10px; */
}
.car_name {
  text-align: center;
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.336); */
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  padding: 8px;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.car_info {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0;
  word-break: break-all;
  padding: 0 2px;
}
.border_right {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 80%;
  width: 3px;

  position: absolute;
  right: 0;
}

.car_details_backdrop {
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.37);
  position: absolute;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;

  left: -100%;
  transition: 0.3s;
}
.car_container:hover .car_details_backdrop {
  left: 0px;
}

.car_info_btn_container {
  display: block;
}
.car_btn {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 15px;
  margin: 10px 0;
  /* border: 1px solid grey; */
  border-radius: 2px;
  background-color: rgb(70, 70, 70);
  color: rgb(255, 255, 255);
  text-align: center;
  transition: 0.5s;
}
.car_btn:hover {
  cursor: pointer;
  opacity: 0.7;
}
.car_btn_icon {
  color: white;
  padding-right: 5px;
  font-size: 16px;
}
</style>
