<template>
  <div>
    <!-- <slider :sliders="sliders" /> -->
    <!-- <br /> -->

    <showcase
      :numberOfAvailableCars="numberOfAvailableCars"
      :brandWithActiveCars="brandWithActiveCars"
      :brands="brands"
      :sliders="sliders"
    />
    <br />
    <why-choose-us :reasons="whyChooseUs" :facilities="services" />
  </div>
</template>

<script>
import * as api from "../config/api.js";
// import Slider from "../components/slider/slider.vue";
import Showcase from "../components/showcase/showcase.vue";
import WhyChooseUs from "../components/whyChooseUs/whyChooseUs.vue";
export default {
  beforeMount() {
    this.getHomeData();
    this.getBrandWithActiveCars();
  },
  components: {
    WhyChooseUs,
    // Slider,
    Showcase,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      sliders: [],
      brands: [],
      whyChooseUs: [],
      services: [],
      numberOfAvailableCars: 0,
      brandWithActiveCars: [],
    };
  },
  methods: {
    getHomeData() {
      this.$http
        .get(api.HOME)
        .then((res) => {
          this.sliders = res.data.sliders;
          this.brands = res.data.brands;
          this.whyChooseUs = res.data.whyChooseUs;
          this.services = res.data.serviceText;
          this.numberOfAvailableCars = res.data.numberOfAvailableCars;
          console.log(this.sliders);
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {});
    },
    getBrandWithActiveCars() {
      this.$http
        .get(api.BRAND_WITH_ACTIVE_CARS)
        .then((res) => {
          this.brandWithActiveCars = res.data;
          console.log(this.brandWithActiveCars);
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {});
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>>
