<template>
  <div class="brand_section_container ">
    <div class="brand_container_headline ">
      <h3>
        Select Brand
        <!--  -->
        <span v-if="numberOfAvailableCars" class="showcase_counter">
          (Available Cars: {{ numberOfAvailableCars }})
        </span>
      </h3>
    </div>

    <div class="brands_container">
      <div
        @click="brandClickHandler(index)"
        class="brandname_container"
        v-for="(brand, index) in brands"
        :key="index"
      >
        <div :class="{ selected: isSeleted(index) }" class="brandname">
          {{ brand.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as slug from "../../config/slug.js";
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {
    brandClickHandler(index) {
      if (this.brands[index] && this.brands[index].name) {
        let brand = this.brands[index].name;
        this.navigation &&
          this.$router.push({ path: slug.ALL_CARS, query: { brand } });

        this.$emit("selectedBrand", brand);
      }
    },
    isSeleted(index) {
      if (this.brands[index] && this.brands[index].name) {
        let brand = this.brands[index].name;
        if (brand === this.selectedBrand) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {},
  props: {
    brands: {
      type: Array,
      default: () => [],
    },
    navigation: {
      type: Boolean,
      default: false,
    },
    selectedBrand: {
      type: String,
      default: null,
    },
    numberOfAvailableCars: {
      default: 0,
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.brand_section_container {
  text-align: center;
}
.brand_container_headline {
  text-align: center;
  font-size: 30px;
  /* font-weight: bolder; */
  /* color: #57ca70; */
  color: #ff2a2a;
}
.showcase_counter {
  /* color: #7a0000; */
  /* font-size: 25px; */
  height: 100%;
}
.brands_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  padding: 40px;
  padding-top: 0px;
  padding-bottom: 20px;
}

.brandname_container {
  padding: 5px;
  /* display: inline-block; */
  text-align: center;
  flex: 1 1 auto;
  margin: 0;
}
.brandname {
  border: 1px solid rgb(197, 202, 207);
  background-color: rgb(245, 238, 238);
  border-radius: 10px;
  padding: 0 25px;
  font-size: 22px;
  font-weight: 500;
}
.brandname:hover {
  cursor: pointer;
  /* background-color: rgb(205, 243, 255); */
  background-color: #FAC5F8;
}

.selected {
  /* background-color: rgb(76, 102, 247); */
  background-color: #FAC5F8;

  /* color: white; */
}
/* .selected:hover {
  background-color: rgb(76, 102, 247);
  color: white;
} */

@media only screen and (max-width: 600px) {
  .showcase_counter {
    text-align: center;
    display: block;
    /* border: 1px solid red; */
  }
  .brands_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    padding: 10px;
  }
  .brandname_container {
    /* width: 30%; */
    flex: 1 1 auto;
    margin: 0;
    /* padding: 15px; */
    /* border: 1px solid red;; */
  }

  .brandname {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 5px;
  }
}
</style>
