import Vue from 'vue'
import App from './App.vue'
import vuetify from './config/vuetify.js'
import router from './config/router.js'
import axios from "./config/axios.js";
import Loader from './components/loader/loader.vue'

import '@fortawesome/fontawesome-free/css/all.css'

Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.component('loader', Loader);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
