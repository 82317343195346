<template>
  <v-app >
    <div class="home_container">
      <header-component />
      <router-view class="app_container"></router-view>
      <footer-component />
    </div>
  </v-app>
</template>

<script>
import HeaderComponent from "./components/header/header.vue";
import FooterComponent from './components/footer/footer.vue'
export default {
  name: "App",

  components: {
    HeaderComponent,
    FooterComponent
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
/* Basic CSS */
@import url("./css/basicStyle.css");

.home_container {
  /* display: none; */
  box-sizing: border-box;
  /* background-color: #F2ECFF; */
  /* background-color: #fffff2; */
  background-color: #E8E8E8;
  /* overflow: hidden; */
  /* padding: 30px; */
}
.app_container {
  min-height: 450px;
}
</style>
