<template>
  <div class="section_container">
    <v-container>
      <!-- Why Choose Us Row -->
      <!-- <v-row>
        <v-col cols="12">
          <br>
          <div class="reason_container_headline">Why Choose Us</div>
        </v-col>
        <v-col
          v-for="(reason, index) in reasons"
          :key="index"
          cols="12"
          xl="3"
          lg="3"
          md="6"
          sm="6"
        >
          <div class="reason">
            <div class="reason_headline">{{ reason.headline }}</div>
            <div class="reason_text">{{ reason.message }}</div>
          </div>
        </v-col>
      </v-row> -->

      <!-- Facility Row -->
      <v-row v-if="facilities.length >= 5" class="facility_row">
        <v-col cols="12">
          <div class="facility_section_headline">
            Our facilities for all Cars
          </div>
        </v-col>
        <v-col cols="12" class="d-flex  py-2 facility_container">
          <div class="facility_text_one">
            <div class="facility_text ">
              {{ facilities[0].message }}
            </div>
          </div>
          <div class="facility_text_two">
            <div class="facility_text  ">
              {{ facilities[1].message }}
            </div>
          </div>
        </v-col>

        <v-col cols="12" class="d-flex  py-2 facility_container">
          <div class="facility_text_three">
            <div class="facility_text ">
              {{ facilities[2].message }}
            </div>
          </div>
          <div class="facility_text_four ">
            <div class="facility_text  ">
              {{ facilities[3].message }}
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="py-2">
          <div class="facility_text_five">
            <div class="facility_text">
              {{ facilities[4].message }}
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {
      // reasons: [
      //   {
      //     headline: "Financing Made Easy",
      //     message:
      //       "Our stress free finace department can find finacial solution to save you money",
      //   },
      // ],
      // facilities: [
      //   { message:"1 Years warranty and 1 Free Service",}
      //   { message:"After Sales service",}
      //   { message:"Dedicated workshops for repairs",}
      //   { message:"Bank Loan facilities available",}
      //   { message:"Our Dedicated Sales team ia always be there for you",}
      // ],
    };
  },
  methods: {},
  mounted() {},
  props: {
    reasons: {
      type: Array,
      default: () => [],
    },
    facilities: {
      type: Array,
      default: () => [],
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.section_container {
  background-color: #dc3c3c;
}
.reason_container_headline {
  color: black;
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.reason {
  background-color: black;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  padding: 10px 15px;
  text-align: justify;
}
.reason:hover {
  cursor: pointer;
  box-shadow: 2px 2px 5px 2px rgb(29, 28, 31);
}
.reason_headline {
  text-transform: uppercase;
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
}
.reason_text {
  color: white;
  font-size: 16px;
}

.facility_section_headline {
  font-weight: bold;
  font-size: 35px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  margin-top: 40px;
}

.facility_text {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  background-color: rgba(255, 255, 255, 0.39);
  padding: 15px;
  border-radius: 20px;
  text-align: center;
  color: white;
  font-weight: bold;
}
.facility_text:hover {
  cursor: pointer;
  box-shadow: 1px -1px 10px 2px rgba(197, 180, 180, 0.336);
}
.facility_text_one {
  padding-right: 50px;
  width: 50%;
}
.facility_text_two {
  padding-left: 50px;
  width: 50%;
}
.facility_text_three {
  padding-right: 25px;
  padding-left: 25px;
  width: 50%;
}
.facility_text_four {
  padding-right: 25px;
  padding-left: 25px;
  width: 50%;
}
.facility_text_five {
  padding-right: 95px;
  padding-left: 95px;
}

@media only screen and (max-width: 960px) {
  .reason_headline,
  .reason_text {
    text-align: center;
  }

  /* .facility_row {
    margin-top: 40px !important;
    background-color: rgb(16, 48, 97);
  } */

  .facility_section_headline {
    margin-top: 0px;
    font-size: 25px;
  }

  .facility_text_one,
  .facility_text_two,
  .facility_text_three,
  .facility_text_four,
  .facility_text_five {
    display: block;
    width: 100%;
    padding: 15px 30px;
  }

  .facility_container {
    display: block !important;
    padding: 0 !important;
    /* border: 1px solid red; */
  }
}
</style>
