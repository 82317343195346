<template>
  <div class="" style="box-sizing: border-box; overflow:hidden">
    <v-row style="background-color: #F6B747" >
      <!-- <v-col cols="4" class="msg_container ">
        <div>
          <div class="msg_headline">
            {{ msg.headline }}
          </div>
          <div class="msg_text">
            {{ msg.text }}
          </div>
        </div>
      </v-col> -->
      <v-col cols="12" class="">
        <v-carousel
          cycle
          :height="carouselHeight + 'px'"
          hide-delimiters
          hide-delimiter-background
          :show-arrows="true"
          class="w-100 "
          interval="6000"
          id="sliderContainer"
        >
          <v-carousel-item v-for="(slide, i) in sliders" :key="i">
            <v-sheet height="100%" color="white" class="slider_img_container">
              <v-img :src="slide.picPath" height="100%" width="100%"></v-img>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  beforeMount() {},
  beforeDestroy() {
    window.removeEventListener(
      "resize",
      this.setCarouselHeightAccordingToScreenWidth("resize destroyed")
    );
  },
  created() {
    window.addEventListener(
      "resize",
      this.setCarouselHeightAccordingToScreenWidth("resize create")
    );
  },
  components: {},
  computed: {},
  data() {
    return {
      carouselHeight: 0,
      msg: {
        headline: "Luxury & Exclusive Used Car Showroom in Dhaka",
        text:
          "Buying a used car is a bit  of a hassle? Leave it to us and live tension-free! We will work hard to ensure you get the best used car within you budget.",
      },
      // sliders:[
      //   {picPath:"https://www.supercars.net/blog/wp-content/uploads/2020/12/2021-Ferrari.jpg"},
      //   {picPath:"https://www.slashgear.com/wp-content/uploads/2020/09/ferrari_portofinom_2.jpg"},
      //   {picPath:"https://www.gannett-cdn.com/presto/2019/11/13/PDTN/49c90a3a-7399-4296-b0f1-4ad4fd90da5b-190398-car-ferrari-Roma.jpg?crop=1379,793,x659,y763"},
      //   {picPath:"https://i1.wp.com/us.motorsactu.com/wp-content/uploads/2020/01/Ferrari-Roma-2020.jpg?fit=1200%2C675&ssl=1"},
      //   {picPath:"https://s1.cdn.autoevolution.com/images/news/gallery/ferrari-may-restart-production-in-italy-on-april-14th_7.jpg"},
      // ]
    };
  },
  methods: {
    setCarouselHeightAccordingToScreenWidth(section) {
      let carousel = document.getElementById("sliderContainer");
      if (carousel) {
        this.carouselHeight = carousel.clientWidth * (180 / 360);
        // console.log("classes===>", )
        // let msgContainer = document.getElementsByClassName("msg_container")[0];

        // msgContainer.style.height = this.carouselHeight + "px";
      }

      // console.log(section,"===>",carousel)
    },
  },
  mounted() {
    this.setCarouselHeightAccordingToScreenWidth("mounted");
  },
  props: {
    sliders: {
      type: Array,
      default: () => [],
    },
  },
  updated() {},
  watch: {
    sliders() {
      this.setCarouselHeightAccordingToScreenWidth();
    },
  },
};
</script>

<style scoped>
.msg_container {
  background-color: #f6b747;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.msg_headline {
  font-size: 30px;
  text-align: center;
  color: black;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 50px;
}
.msg_text {
  font-size: 22px;
  line-height: 25px;
  text-align: justify;
  font-weight: 450;
}

.slider_img_container {
  width: 100%;
  /* background-color: red !important; */
  
}
</style>
