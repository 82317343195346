<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        xl="8"
        lg="8"
        md="7"
        sm="12"
        id="showcaseImage"
        style="position:relative"
      >
        <v-img
          width="100%"
          :src="showcaseImage"
          class="d-flex align-items-center"
        >
          <v-icon
            @click="arrowClickHandler('right')"
            style="position: absolute; right:0"
            color="primary"
          >
            fa-arrow-circle-right
          </v-icon>
          <v-icon
            @click="arrowClickHandler('left')"
            style="position: absolute; left:0"
            color="primary"
          >
            fa-arrow-circle-left
          </v-icon>
        </v-img>
      </v-col>
      <v-col cols="12" class="showcase_images_mobileview showcase_images">
        <v-row>
          <v-col
            cols="4"
            sm="3"
            v-for="(image, index) in carData.images"
            :key="index"
          >
            <div
              @click="carImageClickHandler(index)"
              class="car_details_image"
              :class="{ 'border-primary': index == showcaseImageIndex }"
            >
              <v-img :src="image.picPath"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="5" sm="12">
        <div class="car_data_table">
          <div class="car_name">
            {{ carData.name }}
          </div>
          <div class="px-4">
            <div
              class="car_data_row"
              v-for="(data, index) in carDataTable"
              :key="index"
            >
              <div class="car_data text-left">{{ data.key }}</div>
              <div class="car_data text-right">
                {{ data.value ? data.value : "N/A" }}
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="showcase_images_desktopview showcase_images">
        <v-row>
          <v-col cols="1" v-for="(image, index) in carData.images" :key="index">
            <div
              @click="carImageClickHandler(index)"
              class="car_details_image"
              :class="{ 'border-primary': index == showcaseImageIndex }"
            >
              <v-img :src="image.picPath"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="my-4 showcase_images">
        <div v-html="carData.description" style="text-align:justify"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as api from "../config/api.js";
import * as slug from "../config/slug.js";
// import { carData } from "../demo/demoData.js";
export default {
  beforeMount() {
    this.getCarData();
  },
  components: {},
  computed: {
    carDataTable() {
      return [
        { key: "Body", value: this.carData.body },
        { key: "Kilometers Run", value: this.carData.mileage },
        { key: "Fuel Type", value: this.carData.fuelType },
        { key: "Engine Capacity", value: this.carData.engine },
        { key: "Chassis Number", value: this.carData.chassisNumber },
        { key: "Package", value: this.carData.package },
        { key: "Auction Grade", value: this.carData.auctionGrade },
        { key: "Transmission", value: this.carData.transmission },
        { key: "Drive", value: this.carData.drive },
        { key: "Exterior Color", value: this.carData.exteriorColor },
        { key: "Interior Color", value: this.carData.interiorColor },
      ];
    },
  },
  data() {
    return {
      loadingState: false,
      carData: {},
      showcaseImage: null,
      showcaseImageIndex: 0,
    };
  },
  methods: {
    getCarData() {
      this.urlVerification();
      let id = this.$route.query.id;

      this.$http
        .get(api.CAR + "/" + id)
        .then((res) => {
          this.carData = res.data;
          this.showcaseImage = this.carData.bannerImage;
          this.carData.images = [
            { picPath: this.carData.bannerImage },
            ...this.carData.images,
          ];
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {});
    },
    carImageClickHandler(index) {
      this.showcaseImageIndex = index;
      document.getElementById("showcaseImage").scrollIntoView();
    },
    showcaseImageIndexChangeHandler() {
      this.showcaseImage = this.carData.images[this.showcaseImageIndex].picPath;
    },
    urlVerification() {
      let id = this.$route.query.id;
      // console.log('ID==>', id)
      if (!id) {
        this.$router.push({ path: slug.ALL_CARS });
      }
    },
    showCarImagesInDesktopView(view) {
      if (screen.width >= 960 && view === "desktopView") {
        return true;
      }
      if (screen.width < 960 && view === "mobileView") {
        return true;
      }
      return false;
    },
    arrowClickHandler(direction) {
      if (direction === "left") {
        this.showcaseImageIndex =
          this.showcaseImageIndex == 0
            ? this.carData.images.length-1
            : this.showcaseImageIndex - 1;
      }
      if (direction === "right") {
        this.showcaseImageIndex =
          this.showcaseImageIndex == this.carData.images.length - 1
            ? 0
            : this.showcaseImageIndex + 1;
      }
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    showcaseImageIndex() {
      this.showcaseImageIndexChangeHandler();
      console.log(this.showcaseImageIndex);
    },
  },
};
</script>

<style scoped>
.banner_image {
  height: 400px;
  width: 100%;
  /* border: 3px solid rgb(179, 200, 245); */
}
.car_name {
  background-color: rgba(165, 42, 42, 0.747);
  font-size: 25px;
  font-size: 500;
  line-height: 30px;
  text-transform: uppercase;
  padding: 3px 5px;
  text-align: center;
  color: white;
}
.car_data_table {
  /* background-color: #8b8a8a; */
  background-color: white;
  height: 100%;
  /* padding: 0 15px; */
}
.car_data_row {
  display: flex;
  /* color: white; */
  color: black;
  border-bottom: 1px solid rgb(104, 103, 103);
  padding: 7px 10px;
  text-align: center;
}
.car_data {
  width: 50%;
  font-size: 16px;
}
.showcase_images {
  border: 3px solid rgb(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: white;
}
.showcase_images_mobileview {
  display: none;
}

@media only screen and (max-width: 960px) {
  .showcase_images_mobileview {
    display: block;
  }
  .showcase_images_desktopview {
    display: none;
  }
}
</style>
