<template>
  <!-- <v-row class=" border border-danger">
    <v-col cols="12" class=""> -->
  <div class="footerCopyRightMsg">
    &copy;
    <span>
      Tokyo Motors Limited {{ year }} <span class="mx-4">|</span>
      <span>
        <a href="https://www.techstonebd.com/" target="_blank" class="hLink">
          Powered By Techstone Ltd
        </a>
      </span>
    </span>
  </div>
  <!-- </v-col> -->
  <!-- </v-row> -->
</template>

<script>
// import axios from "axios";
// import * as api from "../../config/api/frontendApi.js";
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  methods: {},
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.footerCopyRightMsg {
  padding: 15px 10px;
  /* background-color: rgb(12, 45, 88); */
  background-color: #585353;
  color: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
}
.hLink {
  text-decoration: none;
  color: aliceblue;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 15px;
}
</style>
