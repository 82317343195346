// const HOST  = 'http://localhost:8000'
const HOST  = 'https://backend.tokyomotors.com.bd'
const PREFIX = "api/frontend";
const BASE_URL = `${HOST}/${PREFIX}`;


export const HEADER = `${BASE_URL}/header`
export const HOME = `${BASE_URL}/home`
export const BRAND = `${BASE_URL}/brand`
export const BRAND_WITH_ACTIVE_CARS = `${BASE_URL}/brand/activecars`

export const ABOUT = `${BASE_URL}/about` // /:section will be added

export const CAR = `${BASE_URL}/car`
export const ALL_CAR = `${BASE_URL}/car/all`
export const CONTACT_PERSON = `${BASE_URL}/contact/person`
export const CONTACT_DATA = `${BASE_URL}/contact/data`
export const CREATE_CONTACT_DATA = `${BASE_URL}/contact`