<template>
  <div class="contact_us_container">
    <v-container>
      <!-- <v-row class="person_data_container">
        <v-col v-for="(person, index) in contactPersons" :key="index" cols="2">
          <div class="person_data">{{ person.name }}</div>
          <div class="person_data">{{ person.designation }}</div>
          <div class="person_data">{{ person.phone }}</div>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12">
          <div
            class="pa-4"
            style="border-radius: 15px; background-color: white; height:100%; border: 1px solid rgba(0,0,0,.2)"
            v-html="contactInformation"
          ></div>
        </v-col>
        <v-col
          cols="12"
          xl="8"
          lg="8"
          md="8"
          sm="12"
          style="box-sizing:border-box; "
        >
          <iframe
            :src="mapLink"
            width="100%"
            height="100%"
            style=" min-height:350px; border-radius: 15px;border: 1px solid rgba(0,0,0,.2)"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </v-col>
      </v-row>

      <v-row class="">
        <v-col cols="12">
          <v-card
            class="px-12 py-4"
            style="border-radius: 15px; background-color:white; border: 1px solid rgba(0,0,0,.2)"
          >
            <v-card-title>Contact Us</v-card-title>
            <v-form ref="form" v-model="valid">
              <v-row>
                <!-- Name -->
                <v-col cols="12" xl="4" lg="4" md="4" sm="12" class="d-flex">
                  <v-text-field
                    outlined
                    label="Name"
                    v-model="formData.name"
                    :rules="formRules.name"
                    hide-details="auto"
                  ></v-text-field>
                  <span style="color: red">*</span>
                </v-col>
                <!-- Phone -->
                <v-col cols="12" xl="4" lg="4" md="4" sm="12" class="d-flex">
                  <v-text-field
                    outlined
                    label="Phone"
                    v-model="formData.phone"
                    :rules="formRules.phone"
                    hide-details="auto"
                  ></v-text-field>
                  <span style="color: red">*</span>
                </v-col>
                <!-- Email -->
                <v-col cols="12" xl="4" lg="4" md="4" sm="12" class="d-flex">
                  <v-text-field
                    outlined
                    label="Email"
                    v-model="formData.email"
                    type="email"
                    :rules="formRules.email"
                    hide-details="auto"
                  ></v-text-field>
                  <span style="color: red">*</span>
                </v-col>
                <!-- Description -->
                <v-col cols="12" class="d-flex">
                  <v-textarea
                    outlined
                    label="Description"
                    v-model="formData.message"
                    :counter="1000"
                    :rules="formRules.message"
                  ></v-textarea>
                  <span style="color: red">*</span>
                </v-col>
                <!-- Buttons -->
                <v-col cols="12" class="text-center">
                  <v-btn
                    :disabled="!valid"
                    @click="submitButtonHandler()"
                    color="success"
                    :loading="submit"
                  >
                    Send
                  </v-btn>
                  <v-btn @click="reset()" medium color="error">Cancel</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import { contactPersons } from "../demo/demoData.js";
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getContactInformation();
  },
  components: {},
  computed: {},
  data() {
    return {
      errors: [],
      loadingState: false,
      contactPersons: [],
      mapLink: "",
      contactInformation: "",
      valid: false,
      submit: false,
      formData: {
        name: null,
        phone: null,
        email: null,
        message: null,
      },
      initFormData: {
        name: null,
        phone: null,
        email: null,
        message: null,
      },
      formRules: {
        name: [(v) => !!v || "Name is required"],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phone: [
          (v) => !!v || "Phone is required",
          (v) =>
            (v && v.trim().length >= 11 && v.trim().length <= 14) ||
            "Phone number is invalid",
          (v) =>
            (v && v.toString().startsWith("01")) || "Phone number is invalid",
        ],
        message: [
          (v) => !!v || "message is required",
          (v) =>
            (v && v.trim().length <= 1000) ||
            "Can not be more than 1000 character",
        ],
      },
    };
  },
  methods: {
    getContactInformation() {
      this.loadingState = true;
      this.$http
        .get(api.CONTACT_DATA)
        .then((res) => {
          // this.contactPersons = res.data.contactPersons;
          console.log("=======>", res.data);

          if (res && res.data && res.data.mapLink) {
            this.mapLink = res.data.mapLink;
          }
          if (res && res.data && res.data.contactInformation) {
            this.contactInformation = res.data.contactInformation;
          }
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    submitButtonHandler() {
      this.submit = true;
      this.$http
        .post(api.CREATE_CONTACT_DATA, { ...this.formData })
        .then((res) => {
          alert("success");
          console.log("Res==>", res.data);

          this.reset();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.errors = error.response.data;
          }
        })
        .finally(() => {
          this.submit = false;
        });
    },
    reset() {
      this.$refs.form.reset();
      this.formData = { ...this.initFormData };
      this.submit = false;
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.contact_us_container {
  /* background-color: #b77777; */
  /* padding: 50px 100px; */
  margin-top: 0px;
}
.person_data_container {
  background-color: #eca983;
  padding: 15px;
  border-radius: 15px;
  /* border: 2px solid red; */
  justify-content: center;
}
.person_data {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  text-align: center;
  line-height: 25px;
  color: black;
  font-weight: 500;
}
</style>
