<template>
  <div class="" style="position:relative">
    <Banner />
    <input type="checkbox" id="menu_check_box" style="display:none" />

    <div class="menuBar mb-0" id="menuBar">
      <v-container style="padding: 0 !important">
        <div class="d-flex align-items-center">
          <div class="navItemContainer ">
            <ul style="margin: 0 !important; padding: 0px 0; ">
              <router-link to="/" class="navItem" tag="li">
                <span class="navItemData">Home</span>
              </router-link>
              <router-link to="/details/about" class="navItem" tag="li">
                <span class="navItemData">About Us</span>
              </router-link>
              <router-link to="/cars/available" class="navItem" tag="li">
                <span class="navItemData">All Cars</span>
              </router-link>
              <router-link to="/cars/sold" class="navItem" tag="li">
                <span class="navItemData">Sold Cars</span>
              </router-link>
              <router-link to="/details/service" class="navItem" tag="li">
                <span class="navItemData">Service</span>
              </router-link>
              <router-link to="/contact" class="navItem" tag="li">
                <span class="navItemData">Contact us</span>
              </router-link>

              <v-list-item
                class="navItem"
                href="https://www.facebook.com/tokyomotors19"
                target="_blank"
              >
                <v-btn class=" mb-1">
                  <v-icon color="primary">fab fa-facebook-square</v-icon>
                </v-btn>
              </v-list-item>
            </ul>
          </div>
        </div>
      </v-container>
    </div>
    <div class="menubar_icon_container">
      <router-link
        tag="a"
        to="/"
        style="z-index: 1; position: absolute; left: 10px"
      >
        <v-icon color="white">fa-home</v-icon>
      </router-link>
      <label
        for="menu_check_box"
        class="menu_bar_icon"
        id="menu_bar_icon"
        style="z-index: 4; position: absolute; right: 10px"
      >
        <v-icon color="white">fa-bars</v-icon>
      </label>
    </div>

    <!-- <v-btn
      @click="goToTop()"
      height="50px"
      width="50px"
      rounded
      fixed
      right
      bottom
      color="primary"
      style="z-index: 4"
    >
      <v-icon color="white">fa-angle-up</v-icon>
      
    </v-btn> -->
    <div class="go_to_top_img_container">
      <v-img
        @click="goToTop()"
        style=""
        width="100%"
        contain
        src="../../assets/scroll_top.png"
      ></v-img>
    </div>
  </div>
</template>

<script>
import Banner from "./banner.vue";
export default {
  created() {
    window.addEventListener("click", this.userClickHandler);
  },
  components: {
    Banner,
  },
  computed: {},
  data() {
    return {
      rotateCounter: 0,
    };
  },
  methods: {
    userClickHandler(event) {
      let menubar = document.getElementById("menuBar");
      let menubarIcon = document.getElementById("menu_bar_icon");
      let menuCheckBox = document.getElementById("menu_check_box");

      if (menubar && menubarIcon && menuCheckBox.checked) {
        if (
          !menubar.contains(event.target) &&
          !menubarIcon.contains(event.target) &&
          !menuCheckBox.contains(event.target)
        ) {
          menuCheckBox.checked = false;
        }
      }
    },
    goToTop() {
      this.rotateCounter++;
      document.getElementsByClassName(
        "go_to_top_img_container"
      )[0].style.transform = `rotate(${720 * this.rotateCounter}deg)`;
      window.scrollTo(0, 0);
      // document.getElementsByClassName('go_to_top_img_container')[0].style.transform = 'rotate(0deg)';
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.menu_bar_icon {
  display: none;
}
.navItemContainer {
  width: 100%;
  text-align: center;
}
.navItem {
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
.navItemData {
  font-size: 20px;
  font-weight: 500;
  color: white;
  /* font-weight: bolder; */
  padding-bottom: 5px;
}
.navItemData:hover {
  /* color: blueviolet; */
  color: #042f4e;
  /* border-bottom: 1px solid blueviolet; */
}
.menuBar {
  position: sticky;
  top: 0;
  z-index: 3;
  margin-bottom: 1px;
  /* background-color: #B6BEBC; */
  /* background-color: #557a95; */
  background-color: #5b8a61;
  /* display: none; */
}

.menubar_icon_container {
  display: none;
  background-color: #5b8a61;
  display: relative;
  height: 45px;
}
a {
  text-decoration: none;
}

.go_to_top_img_container {
  z-index: 4;
  position: fixed;
  width: 100px;
  right: 0px;
  bottom: 10px;
  transition: 0.5s;
}

@media only screen and (max-width: 600px) {
  .menu_bar_icon {
    display: block;
  }
  .menuBar {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 70%;
    height: 100%;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 0.3s;
    display: flex;
    align-items: center;
    padding: 0;
  }
  .menubar_icon_container {
    display: block;
    padding: 10px 15px;
  }
  .navItem {
    display: block;
    text-align: left;
    margin-left: 30px;
    margin-top: 10px;
    /* background-color: brown; */
    background-color: #3d4a3e;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  #menu_check_box:checked ~ .menuBar {
    left: 0;
  }

  .go_to_top_img_container {
    width: 60px;
  }
}
</style>
