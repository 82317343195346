<template>
  <div>
    <header-component />
    
  </div>
</template>

<script>
import HeaderComponent from '../components/header/header.vue'
export default {
  beforeMount() {},
  components: {
    HeaderComponent
  },
  computed: {},
  data() {
    return {

    };
  },
  methods: {},
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
</style>>