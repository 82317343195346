<template>
  <div style="position:relative" class="">
    <!-- <div class="showcase_headbar ">
      <v-container>
        <v-row class="">
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" class="py-0">
            <div class="showcase_welcome">Welcome to Tokyo Motors</div>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" class="py-0">
            <div class="showcase_counter">
              Available Cars: {{ numberOfAvailableCars }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <br /> -->
    <!-- Brand -->
    <!-- <v-container> -->
    <brands
      :brands="brands"
      :numberOfAvailableCars="numberOfAvailableCars"
      :selectedBrand="selectedBrand"
      @selectedBrand="selectedBrandHandler"
      id="showcase_brand"
    />
    <!-- </v-container> -->
    <v-container v-if="!selectedBrand">
      <slider :sliders="sliders" />
    </v-container>
    <!-- Car Showcase -->
    <div id="showcase_container">
      <v-container v-if="selectedBrand">
        <v-row
          v-for="(brand, index) in computedBrandWithActiveCars"
          :key="index"
          class="justify-content-center brand_cars_container"
        >
          <!-- Brand Name -->
          <v-col cols="12" class="pa-0">
            <h3
              class="text-center cars_container_brand_name"
              :id="`${brand.name}`"
            >
              {{ brand.name }}
              <span class="available_car_number"
                >({{ brand.active_cars.length }} available car )</span
              >
            </h3>
          </v-col>
          <!-- Cars -->
          <showcase-car
            v-for="(car, index) in brand.active_cars"
            :key="index"
            :car="car"
          />
          <!-- <v-col
            cols="12"
            xl="3"
            lg="3"
            md="4"
            sm="6"
            v-for="i in numberOfComingSoonCars(brand.active_cars.length)"
            :key="i"
            class="pa-3 car_container comingsoon_container"
          >
            <div
              style="height: 100%; width: 100%; border: 1px solid rgba(0,0,0,.2); position:relative;"
            >
              <v-img
                src="../../assets/coming_soon.jpeg"
                height="100%"
                width="100%"
                class="comming_soon_img"
              >
              </v-img>
              <div
                style="position:absolute; top:0; left:0; height:100%; width:100%; display:flex; justify-content:center; align-items:center; font-size:22px; color:white; font-weight:600"
              >
                Coming Soon
              </div>
            </div>
          </v-col> -->
        </v-row>
        <!-- 
          <div
            class="text-left"
            style="padding:10px 15px; position:sticky; bottom:10px; left:10px;"
          >
            <v-btn rounded color="primary" @click="backToBrand()">
              Top to showcase
            </v-btn>
          </div> 
        -->
      </v-container>
    </div>
  </div>
</template>

<script>
// Data updated
import Brands from "./brands.vue";
import Slider from "../slider/slider.vue";
import ShowcaseCar from "./showcaseCar.vue";
import * as slug from "../../config/slug.js";
export default {
  beforeMount() {},
  components: {
    Brands,
    Slider,
    ShowcaseCar,
  },
  computed: {
    computedBrandWithActiveCars() {
      let data = this.brandWithActiveCars.filter((brand) => {
        return brand.name == this.selectedBrand;
      });
      return data;
    },
  },
  data() {
    return {
      // numberOfAvailableCars: 63,
      // brands: [
      //   {
      //     id: 3,
      //     name: "Esquire",
      //   },
      // ],
      cars: [
        {
          bannerImage:
            "https://s1.cdn.autoevolution.com/images/news/gallery/ferrari-may-restart-production-in-italy-on-april-14th_7.jpg",
          name: " Ferrari F8 Tributu",
          model: 2019,
          fuelType: "Petrol",
          engine: 3990,
        },
        {
          bannerImage:
            "https://s1.cdn.autoevolution.com/images/news/gallery/ferrari-may-restart-production-in-italy-on-april-14th_7.jpg",
          name: " Ferrari F8 Tributu",
          model: 2019,
          fuelType: "Petrol",
          engine: 3990,
        },
        {
          bannerImage:
            "https://s1.cdn.autoevolution.com/images/news/gallery/ferrari-may-restart-production-in-italy-on-april-14th_7.jpg",
          name: " Ferrari F8 Tributu",
          model: 2019,
          fuelType: "Petrol",
          engine: 3990,
        },
        {
          bannerImage:
            "https://s1.cdn.autoevolution.com/images/news/gallery/ferrari-may-restart-production-in-italy-on-april-14th_7.jpg",
          name: " Ferrari F8 Tributu",
          model: 2019,
          fuelType: "Petrol",
          engine: 3990,
        },
        {
          bannerImage:
            "https://s1.cdn.autoevolution.com/images/news/gallery/ferrari-may-restart-production-in-italy-on-april-14th_7.jpg",
          name: " Ferrari F8 Tributu",
          model: 2019,
          fuelType: "Petrol",
          engine: 3990,
        },
        {
          bannerImage:
            "https://s1.cdn.autoevolution.com/images/news/gallery/ferrari-may-restart-production-in-italy-on-april-14th_7.jpg",
          name: " Ferrari F8 Tributu",
          model: 2019,
          fuelType: "Petrol",
          engine: 3990,
        },
        {
          bannerImage:
            "https://s1.cdn.autoevolution.com/images/news/gallery/ferrari-may-restart-production-in-italy-on-april-14th_7.jpg",
          name: " Ferrari F8 Tributu",
          model: 2019,
          fuelType: "Petrol",
          engine: 3990,
        },
        {
          bannerImage:
            "https://s1.cdn.autoevolution.com/images/news/gallery/ferrari-may-restart-production-in-italy-on-april-14th_7.jpg",
          name: " Ferrari F8 Tributu",
          model: 2019,
          fuelType: "Petrol",
          engine: 3990,
        },
      ],
      selectedBrand: null,
    };
  },
  methods: {
    selectedBrandHandler(item) {
      this.selectedBrand = item === this.selectedBrand ? null : item;
      let itemElement = document.getElementById("showcase_container");
      if (itemElement && this.selectedBrand) {
        // console.log(itemElement);
        setTimeout(() => {
          itemElement.scrollIntoView();
        }, 500);
      }

      // console.log(item);
    },
    backToBrand() {
      document.getElementById("showcase_brand").scrollIntoView();
    },
    numberOfComingSoonCars(numberOfCars) {
      if (numberOfCars > 0 && numberOfCars < 4) {
        if (screen.width < 960) return 2 - numberOfCars;
        if (screen.width < 1264) return 3 - numberOfCars;
        if (screen.width >= 1264) return 4 - numberOfCars;
      }
      return 0;
    },
  },
  mounted() {},
  props: {
    numberOfAvailableCars: {
      type: Number,
      default: 0,
    },
    brands: {
      type: Array,
      default: () => [],
    },
    brandWithActiveCars: {
      type: Array,
      default: () => [],
    },
    sliders: {
      type: Array,
      default: () => [],
    },
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
.showcase_headbar {
  background-color: #232628;
  /* padding: 10px; */
}
.showcase_welcome {
  color: white;
  font-size: 35px;
  font-weight: 500;
  text-align: left;
}
.showcase_counter {
  color: white;
  font-size: 22px;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.brand_text {
  text-align: center;
  font-size: 45px;
  font-weight: bolder;
  color: #57ca70;
}

.brandname {
  border: 1px solid rgb(197, 202, 207);
  border-radius: 10px;
  padding: 0px 50px;
  display: inline-block;
  margin: 10px 7px;
  font-size: 22px;
  font-weight: 500;
}
.brandname:hover {
  cursor: pointer;
  background-color: rgb(205, 243, 255);
}

.brand_cars_container {
  background-color: rgba(226, 219, 219, 0.781);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #00b284;
  /* border: 1px solid #57CA70; */
}
.cars_container_brand_name {
  /* background-color: bisque; */
  background-color: #57ca70;
  padding: 5px;
  border-radius: 10px;
  font-size: 30px;
  color: #232628;
}
.cars_container_brand_name .available_car_number {
  font-size: 20px;
}

.comming_soon_img {
  filter: blur(5px);
}

@media only screen and (max-width: 960px) {
  .showcase_welcome {
    text-align: center;
    /* border: 1px solid red; */
  }
  .showcase_counter {
    text-align: center;
    display: block;
    /* border: 1px solid red; */
  }
}
@media only screen and (max-width: 600px) {
  .comingsoon_container {
    display: none;
  }
  .showcase_welcome {
    font-size: 25px;
  }

  .cars_container_brand_name {
    font-size: 20px;
  }

  .available_car_number {
    display: block;
    font-size: 16px;
  }
}
</style>
