<template>
  <v-container>
    <loader v-if="loadingState" />
    <div v-else>
      <v-img
        :src="picPath"
        width="100%"
        style="border-radius: 5px"
        v-if="picPath"
      >
      </v-img>
      <br />
      <div
        v-html="message"
        class="briefDescription"
        v-if="message"
      ></div>
    </div>
  </v-container>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getData();
  },
  components: {  },
  computed: {},
  data() {
    return {
      loadingState: false,
      picPath: null,
      message: null,
      section: this.$route.params.section,
    };
  },
  methods: {
    reset() {
      this.picPath = null;
      this.message = null;
      this.loadingState = false;
    },
    getData() {
      this.reset();
      this.loadingState = true;
      this.$http
        .get(api.ABOUT + "/" + this.section)
        .then((res) => {
          console.log("Res==>", res.data);
          if (res && res.data.picPath && res.data.message) {
            this.picPath = res.data.picPath;
            this.message = res.data.message;
          }
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    "$route.params"() {
      this.section = this.$route.params.section;
      this.getData();
    },
  },
};
</script>

<style scoped>
.briefDescription {
  font-size: 20px;
  word-spacing: 2px;
  word-break: keep-all;
  text-align: justify;
  border: 1px solid #00B284;
  padding: 15px;
  background-color: white;
}
</style>
