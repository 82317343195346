import Vue from "vue";
import VueRouter from "vue-router";
import * as slug from "./slug.js";

import Home from "../pages/home.vue";
import CarDetais from "../pages/carDetails.vue";
import AllCars from "../pages/allCars.vue";
import About from "../pages/about.vue";
import ContactUs from "../pages/contactUs.vue";

import Test from "../pages/test.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",

  routes: [
    {
      path: slug.HOME,
      component: Home,
    },
    {
      path: slug.ALL_CARS,
      component: AllCars,
    },
    {
      path: slug.CAR_DETAILS,
      component: CarDetais,
    },
    {
      path: slug.ABOUT,
      component: About,
    },
    {
      path: slug.CONTACT_US,
      component: ContactUs,
    },

    // Test
    {
      path: slug.TEST,
      component: Test,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      //if user click the back button, scroll back into the position where he left
      return savedPosition;
    }
    return {
      //sroll to the top
      x: 0,
      y: 0,
    };
  },
});

export default router;
