<template>
  <div class="banner">
    <v-container>
      <v-row class="">
        <v-col
          cols="4"
          xl="2"
          lg="2"
          md="2"
          sm="2"
          class=" px-1 center"
          style="justify-content: start;"
        >
          <v-img :src="logo" width="100%" max-width="100px"></v-img>
        </v-col>
        <v-col cols="8" xl="7" lg="7" md="7" sm="7" class="pa-0 pb-2 center">
          <div>
            <div class="capital headerText">Tokyo Motors Limited</div>
            <div class="capital midText">sister concern of</div>
            <div class="capital companyText">Neela Treding CO.</div>
          </div>
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="3"
          sm="3"
          style="justify-content: end;"
          class="pa-0 center"
        >
          <div class="banner_phn_container">
            <p
              class="bannerPhn"
              v-for="(contact, index) in contacts"
              :key="index"
            >
              <v-icon size="16px" color="white" class="pb-1"
                >fas fa-phone-alt</v-icon
              >
              <a
                :href="'tel:' + contact.phone"
                style="text-decoration: none; color: white"
              >
                +88{{ contact.phone }}
              </a>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as api from "../../config/api.js";
import logo from "../../assets/newLogo.jpeg";
export default {
  beforeMount() {
    this.getHeaderData();
  },
  components: {},
  computed: {},
  data() {
    return {
      // logo: "../../assets/newLogo.jpeg",
      logo: logo,
      contacts: [
        //  {phone:'+8801940506070'},
      ],
    };
  },
  methods: {
    getHeaderData() {
      this.$http(api.HEADER)
        .then((res) => {
          this.contacts = res.data.contacts;
          console.log("cotact===>", res.data);
        })
        .catch((error) => {
          console.log("err==>", error.response.data);
        });
    },
  },
  mounted() {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/png";
    link.rel = "shortcut icon";
    link.href = this.logo;
    document.getElementsByTagName("head")[0].appendChild(link);
  },
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.banner {
  background-color: #57ca70;
}

.bannerPhn {
  font-size: 23px;
  letter-spacing: 1px;
  line-height: 27px;
  color: white;
  /* border: 1px solid red; */
  padding: 0 !important;
  margin: 0px;
  text-align: end;
}

.capital {
  text-transform: uppercase;
  text-align: center;
  color: white;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerText {
  font-size: 33px;
}

.midText {
  font-size: 18px;
}

.companyText {
  font-size: 23px;
  color: whitesmoke;
  /* font-weight: bold; */
  letter-spacing: 1px;
}

@media only screen and (max-width: 960px) {
  .headerText {
    font-size: 25px;
  }
  .midText {
    font-size: 16px;
  }
  .companyText {
    font-size: 20px;
  }
  .bannerPhn {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .headerText {
    font-size: 20px;
    font-weight: 500;
  }
  .midText {
    font-size: 14px;
    font-weight: 500;
  }
  .companyText {
    font-size: 16px;
    font-weight: 500;
  }
  .banner_phn_container {
    font-weight: 500;
    width: 100%;
    text-align: center;
    background-color: #3d4a3e;
    /* background-color: blueviolet; */
  }
  .bannerPhn {
    font-size: 16px;
    letter-spacing: 0;
    display: inline-block;
    margin: 5px 15px;
  }
}
@media only screen and (max-width: 400px) {
  /* .headerText {
    font-size: 16px;
  }
  .midText {
    font-size: 14px;
  }
  .companyText {
    font-size: 16px;
  }
  .bannerPhn {
    font-size: 13px;
    letter-spacing: 0;
  } */
}
</style>
